var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":20}},[_c('a-form-item',{attrs:{"label":"Nome do restaurante no aplicativo","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'name',
							{
								initialValue: _vm.merchant.name,
								rules: [
									{
										required: true,
										message:
											'Por favor, informe um nome!',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'name',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tinitialValue: merchant.name,\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe um nome!',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o nome do restaurante que será mostrado para os clientes no aplicativo"}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Número de mesas","has-feedback":""}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'amount_places',
							{
								initialValue: _vm.merchant.amount_places,
								rules: [
									{
										required: true,
										message:
											'Por favor, informe o numero de mesas disponíveis em seu restaurante!',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'amount_places',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tinitialValue: merchant.amount_places,\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe o numero de mesas disponíveis em seu restaurante!',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"0","min":1}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{attrs:{"label":"Especialidade","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'main_category',
							{
								initialValue: _vm.merchant.main_category,
								rules: [
									{
										required: true,
										message:
											'Por favor, selecione uma especialidade!',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'main_category',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tinitialValue: merchant.main_category,\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t'Por favor, selecione uma especialidade!',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],staticClass:"select",attrs:{"placeholder":"Selecione a especialidade do seu restaurante","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((_vm.categories),function(category){return _c('a-select-option',{key:category.id},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)],1)],1)],1),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":"Bio","has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'presentation',
							{
								initialValue: _vm.merchant.presentation,
								rules: [
									{
										required: true,
										message:
											'Por favor, preencha a apresentação do seu restaurante!',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'presentation',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\tinitialValue: merchant.presentation,\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t'Por favor, preencha a apresentação do seu restaurante!',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Faça um pequena apresentação do seu restaurante para os clientes do aplicativo.","auto-size":{ minRows: 1, maxRows: 3 }}})],1)],1)],1)],1),_c('a-row',{staticClass:"buttons",attrs:{"type":"flex","justify":"center"}},[_c('a-col',[_c('a-button',{staticStyle:{"width":"300px"},attrs:{"type":"primary"},on:{"click":_vm.onForward}},[_vm._v(" Avançar "),_c('a-icon',{attrs:{"type":"right"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }