<template>
	<div class="container">
		<a-form :form="form">
			<a-row :gutter="16">
				<a-col :span="20">
					<a-form-item
						label="Nome do restaurante no aplicativo"
						has-feedback
					>
						<a-input
							v-decorator="[
								'name',
								{
									initialValue: merchant.name,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe um nome!',
										},
									],
								},
							]"
							placeholder="Informe o nome do restaurante que será mostrado para os clientes no aplicativo"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="4">
					<a-form-item label="Número de mesas" has-feedback>
						<a-input-number
							v-decorator="[
								'amount_places',
								{
									initialValue: merchant.amount_places,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o numero de mesas disponíveis em seu restaurante!',
										},
									],
								},
							]"
							placeholder="0"
							:min="1"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="10">
					<a-form-item label="Especialidade" has-feedback>
						<a-select
							v-decorator="[
								'main_category',
								{
									initialValue: merchant.main_category,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione uma especialidade!',
										},
									],
								},
							]"
							placeholder="Selecione a especialidade do seu restaurante"
							class="select"
							show-search
							option-filter-prop="children"
							:filter-option="filterOption"
						>
							<a-select-option
								v-for="category in categories"
								:key="category.id"
							>
								{{ category.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col>
					<a-form-item label="Bio" has-feedback>
						<a-textarea
							v-decorator="[
								'presentation',
								{
									initialValue: merchant.presentation,
									rules: [
										{
											required: true,
											message:
												'Por favor, preencha a apresentação do seu restaurante!',
										},
									],
								},
							]"
							placeholder="Faça um pequena apresentação do seu restaurante para os clientes do aplicativo."
							:auto-size="{ minRows: 1, maxRows: 3 }"
						/>
					</a-form-item>
				</a-col>
			</a-row>
		</a-form>
		<a-row type="flex" justify="center" class="buttons">
			<a-col>
				<a-button
					type="primary"
					style="width: 300px;"
					@click="onForward"
				>
					Avançar
					<a-icon type="right" />
				</a-button>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { axios } from '@/utils/axios'
import notification from 'ant-design-vue/es/notification'

export default {
	name: 'MerchantInfo',
	directives: { mask },
	data() {
		return {
			categories: [],
			form: this.$form.createForm(this),
		}
	},
	computed: {
		...mapGetters('registerForms', ['merchant']),
	},
	async beforeCreate() {
		try {
			this.categories = await axios.get('/v1/categories/')
		} catch (err) {
			notification.error({
				message: 'Falha de conexão',
				description: 'Erro ao baixar algumas informações do servidor',
				duration: 6,
			})
		}
	},
	methods: {
		...mapActions('registerForms', ['addMerchant']),
		onForward() {
			this.form.validateFields(this.handleAddMerchant)
		},
		async handleAddMerchant(err, merchant) {
			if (err) return Promise.reject()

			try {
				await this.addMerchant({ merchant })

				this.$router.push('/steps/agreement/')
			} catch (error) {
				return Promise.reject(error)
			}
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			)
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);

	.select {
		font-weight: normal;
	}

	.buttons {
		margin: 40px auto 10px;
	}
}
</style>
